
import './App.css';
import { Button, Card, Col, Container, Row, Form, Navbar, Nav } from 'react-bootstrap';
import Moment from 'moment';
import {useEffect, useState} from "react"
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from 'react-paginate';
import Highlights from '../src/Highlights'
import { Link } from 'react-router-dom';
//import logo from './images/logo.png'
import {Heart, HeartFill } from 'react-bootstrap-icons';
import axios from 'axios';
import GlobalNavbar from './Components/Navbar';



function App() {

/**
 * 
 const fetchData = () => 
  Promise.resolve({
    data: inputdata
  })
 * 
 */
  


const [filter, setFilter] = useState("")
const [source, setSource] = useState("")
const [startDate, setStartDate] = useState(new Date("2022-01-01T23:59:52Z"));
const [endDate, setEndDate] = useState(new Date());
const [articles, setArticles] = useState([]); 
const [searchField, setSearchField] = useState("")
const [itemsOnPage, setItemsOnPage] = useState(15)
const [isLoading, setIsLoading] = useState(true)
const [liked, SetLiked] = useState(false)
const [countTotal, setCountTotal] = useState(0)



let val = ""

let heroku_source = "https://stark-atoll-13145.herokuapp.com/"

 


/**
 * 
 const fetchData = () => {
  setIsLoading(true)
  fetch('http://localhost:5000/articles?')
  .then(response => response.json())
  .then(data => {
    console.log("Here is the respone from the server" + data.rows)
    setArticles(data.rows)
    setIsLoading(false)
  })
  }
 
useEffect(() => {
   // fetchData()
    
}, [])

 
useEffect( () => {
  fetch('http://localhost:5000/articleCount')
   .then(response => response.json())
   .then(data => {
         setCountTotal(data.rows[0].count)})
   .then(console.log(countTotal))
       }, [countTotal])


 *  */

       useEffect( () => {
        fetch(heroku_source)
         .then(response => console.log(response.json()))
         .then(data => {
               setCountTotal(data.rows[0])})
         .then(console.log(countTotal))
             }, [countTotal])
      
       
 
 const toggleFavorite = (e) => {
    //console.log("calling")
    e.preventDefault()
  const {value} = e.target.dataset 
 
  axios.put('https://stark-atoll-13145.herokuapp.com/toggleFav', value)
   .then(() => SetLiked(!liked))
   

 }

//console.log(articles)
//console.log(currentItems)

const handleChange = (event) => {
  setFilter(event.target.value)
}


const handleChangeNumber = (event) => {
  setItemsOnPage(event.target.value)
}
const handleSourceChange = (event) => {
  setSource(event.target.value)
}

   
const handleButton = () => {
      setEndDate(new Date());
      setStartDate(new Date("2022-01-01T23:59:52Z"))
      setFilter("")
      setSource("")

}

 
//const startDate = new Date("2022-03-28T23:59:52Z")
//const endDate = new Date("2022-03-31T23:59:52Z")
  
const columnsPerRow = 3;
Moment.locale('en')


function Items({currentItems}) {

  return (
    <Row>
      
    { currentItems && currentItems
    .map((item) => (
      <Col lg={12} >
      <Card className="paddingCard" key={item.id}>
        <Row>
        <Col lg={3}>
          <Card.Img src={item.article_image}></Card.Img>
        </Col>

        <Col lg={7}>
          <Card.Body>
          <Card.Title>
              <a target="_blank"  style={{textAlign: 'left'}} href={item.article_url} >{item.article_title}</a>
          </Card.Title>
          <Card.Subtitle className="source">
                  {item.article_source}
          </Card.Subtitle>
          <Card.Text className="text-muted date">
            {Moment(item.article_date).format('MMM DD YYYY')} 
            
          </Card.Text>
          
          </Card.Body>
        
        </Col>

        <Col lg={2}>
          <Button type="button" variant="link" data-value={item.id} onClick={(e) => toggleFavorite(e)}>
              {item.favorite? "Liked": "Like"}
            </Button>
        </Col>
        </Row>
    </Card>
    </Col>
    ))}
    </Row>
    )     
    }

  


  function PaginatedItems({itemsPerPage}){
        // We start with an empty list of items.
      const [currentItems, setCurrentItems] = useState(null);
      const [pageCount, setPageCount] = useState(0);
 
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
      const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % countTotal;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };



    useEffect(() => {
     
      window.scrollTo(0, 0)
      setIsLoading(true)
      let url = 'https://stark-atoll-13145.herokuapp.com/articles?'
      fetch(url +  new URLSearchParams({
        source: source,
        provider: filter,
        startDate: startDate,
        endDate: endDate,
        search: searchField,
        itemOffset: itemOffset,
        endOffset: endOffset

      }))
      .then(response => response.json())
      .then(data => {
        setCurrentItems(data.data)
        setCountTotal(data.size)
        setPageCount(Math.ceil(countTotal/ itemsPerPage))   
      })
      .then (setIsLoading(false))


          
      /**
       * .then(data => {
        setCurrentItems(data.rows
          .sort((a,b) => new Date(b.article_date) - new Date(a.article_date))
          .filter(it => it.article_title.includes(filter))
          .filter(it => it.article_source.includes(source))
          .filter(it => new Date(it.article_date).getTime() >= startDate.getTime() && new Date(it.article_date).getTime() <= endDate.getTime() )
          .filter(it => it.article_title.toLowerCase().includes(searchField.toLowerCase()))
         .slice(itemOffset, endOffset))
         setPageCount(Math.ceil(articles.filter(it => it.article_title.includes(filter)).filter(it => it.article_source.includes(source)).filter(it => new Date(it.article_date).getTime() >= startDate.getTime() && new Date(it.article_date).getTime() <= endDate.getTime() ).filter(it => it.article_title.toLowerCase().includes(searchField.toLowerCase())).length / itemsPerPage));
      })
      console.log(articles)
    }, [itemOffset, itemsPerPage])
       * 
       */
  }, [itemOffset, itemsPerPage])

    
    return (
      <>
 
        <Items currentItems={currentItems} />
        <Row>
      
        <ReactPaginate
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="< previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
        </Row>
        
      </>
    );
  }
  

  //add is list keys
  
  return (
    
    <div className="App">
     
     <GlobalNavbar searchField={searchField} setSearchField={setSearchField}></GlobalNavbar>

   
   

      <Container>
            <Row>
          <Col md={3}>
            <Card className='filters'>
            <div class="filterForm">
              <Form.Label>Filter by SVOD Service</Form.Label>
              <Form.Select value = {filter} onChange={handleChange}>
                    <option value="">Choose SVOD Service</option>
                    <option value="Netflix">Netflix</option>
                    <option value="Disney">Disney</option>
                    <option value="HBO Max">HBO Max</option>
                    <option value="Paramount">Paramount</option>
                    <option value="Discovery">Discovery +</option>
                    <option value="Apple">Apple TV+</option>
                    <option value="DStv">DStv</option>
                    <option value="Showmax">Showmax </option>
                    
                     </Form.Select>

                <Form.Label>Filter by Source</Form.Label>
                  <Form.Select value = {source} onChange={handleSourceChange}>
                    <option value="">Choose News Source</option>
                    <option value="Reuters">Reuters</option>
                    <option value="Business Insider">Business Insider</option>
                    <option value="News24">News 24</option>
                    <option value="Bloomberg">Bloomberg</option>
                    <option value="Variety">Variety</option>
                    <option value="CNN">CNN</option>
                    <option value="Forbes">Forbes</option>
                    <option value="TechCrunch">Tech Crunch</option>
                    <option value="TechRadar">Tech Radar</option>
                    <option value="MyBroadband">My Broadband</option>
                    <option value="Tech Central">Tech Central</option>
                    <option value="Digital TV Europe">Digital TV Europe</option>
                
                </Form.Select>

                <Form.Label>Select Start Date</Form.Label>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />

                <Form.Label>Select End Date</Form.Label>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />

                <>
                <Button variant="light" onClick={handleButton}> Clear Filters</Button>
                </>
            
               
              </div>
            </Card>
            </Col>
              
            <Col md={9}>
            
           
            <Form.Label># of Articles Per Page</Form.Label>
              <Form.Select value = {itemsOnPage} onChange={handleChangeNumber}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="100">200</option>
               
                </Form.Select>
                <PaginatedItems itemsPerPage={itemsOnPage} />
              </Col>
            </Row>
        

      </Container>
    </div>
  );
}


export default App;

//<Row lg={columnsPerRow} md={2}>{newsItems}</Row>
//<PaginatedItems itemsPerPage={4} />
