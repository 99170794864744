import React from 'react'
import { Card, Form, Button} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default function Sidefilters(props) {

  const filter = props.filter
  const source = props.source
  const startDate = props.startDate
  const setEndDate = props.setEndDate
  const setStartDate = props.setStartDate
  const endDate = props.endDate



  const handleChange = (event) => {
    props.setFilter(event.target.value)
  }

  const handleSourceChange = (event) => {
    props.setSource(event.target.value)
  }

  const handleButton = () => {
    setEndDate(new Date());
    setStartDate(new Date("2022-01-01T23:59:52Z"))
    props.setFilter("")
    props.setSource("")


}
  return (
    <Card style={{border: '0'}}className='filters'>
        <Row>
          <Col lg={3}>

              <Form.Label>Filter by SVOD Service</Form.Label>
              <Form.Select value = {filter} onChange={handleChange}>
                    <option value="">Choose SVOD Service</option>
                    <option value="Netflix">Netflix</option>
                    <option value="Disney">Disney</option>
                    <option value="HBO Max">HBO Max</option>
                    <option value="Paramount">Paramount</option>
                    <option value="Discovery">Discovery +</option>
                    <option value="Apple">Apple TV+</option>
                    <option value="DStv">DSTV</option>
                </Form.Select>
                </Col>

              <Col lg={3}> 
              <Form.Label>Filter by Source</Form.Label>
                  <Form.Select value = {source} onChange={handleSourceChange}>
                    <option value="">Choose News Source</option>
                    <option value="Reuters">Reuters</option>
                    <option value="Business Insider">Business Insider</option>
                    <option value="News24">News 24</option>
                    <option value="Bloomberg">Bloomberg</option>
                    <option value="Variety">Variety</option>
                    <option value="CNN">CNN</option>
                    <option value="Forbes">Forbes</option>
                    <option value="TechCrunch">Tech Crunch</option>
                    <option value="TechCrunch">Tech Radar</option>
                    <option value="MyBroadband">My Broadband</option>
                    <option value="Tech Central">Tech Central</option>
                </Form.Select>
              </Col>
               
              <Col lg={3}>
              <Form.Label>Select Start Date</Form.Label>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
              </Col>

              <Col lg={3}>
                <Form.Label>Select End Date</Form.Label>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} /> 
              </Col>

              <Row>
              <>
                <Button variant="light" onClick={handleButton}> Clear Filters</Button>
                </>

              </Row>
              
            
              

        </Row>
           
            </Card>
  )
}
