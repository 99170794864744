import React from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap';
import Moment from 'moment';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';


export default function Feed(props) {

let currentItems = props.data

let secondRow = props.secondRow

let thirdRow = props.thirdRow

console.log(props.secondRow)

Moment.locale('en')

const removeFav = (e) => {
  //console.log("calling")
const {value} = e.target.dataset 
console.log(value)
axios.put('https://stark-atoll-13145.herokuapp.com/removeFav', value)
.then(() => props.setDeleted(!props.deleted) )

}


  return (
    <div>

    <h3>Latest Stories</h3>
   
    <Row>
   
    { currentItems&& currentItems
    .map((item) => (

      <Col lg={12} >
      <Card  style={{border:'0px'}} key={item.id}>
        
          <Card.Img style={{height: '40vh', objectFit: 'cover'}} variant="top" src={item.article_image}></Card.Img>

          <Card.Body>
          <Card.Subtitle className="source">
                  {item.article_source}
          </Card.Subtitle>
          <Card.Title >
              <a target="_blank" href={item.article_url} className="link">{item.article_title}</a>
          </Card.Title>
          
          <Card.Text className="text-muted date">
            {Moment(item.article_date).format('MMM DD YYYY')} 
          </Card.Text>

         

        

            </Card.Body>

            <Button variant="link" data-value={item.id} onClick={removeFav} className="delete-button">
              x
            </Button>
        
    </Card>
   
    </Col>
    ))}
    </Row>
  
   

    <Row>
   
    { secondRow && secondRow
    .map((item) => (
      <Col lg={3} >
      <Card style={{border:'0px'}} key={item.id}>
        
          <Card.Img style = {{height: '25vh', objectFit: 'cover'}} variant="top" src={item.article_image}></Card.Img>

          <Card.Body>
          <Card.Subtitle className="source">
                  {item.article_source}
          </Card.Subtitle>
          <Card.Title >
              <a target="_blank" href={item.article_url} className="link">{item.article_title}</a>
          </Card.Title>
          
          <Card.Text className="text-muted date">
            {Moment(item.article_date).format('MMM DD YYYY')} 
          </Card.Text>
          </Card.Body>

          <Button variant="link" data-value={item.id} onClick={removeFav} className="delete-button">
              x
            </Button>
        
    </Card>
   
    </Col>
    ))}
    </Row>
    <h3>Making Headlines</h3>

    <hr></hr>

    <Row>
      
   
    { thirdRow && thirdRow
    .map((item) => (
      <Col lg={6}>

      <Card style={{border:'0px'}} key={item.id}>

      <Row>
      <Col lg={3}>
      <Card.Img style = {{ objectFit: 'cover'}} src={item.article_image}></Card.Img>
      </Col>

      <Col lg={9}>
      <Card.Body style={{paddingTop: '0'}}>
          <Card.Subtitle className="source">
                  {item.article_source}
          </Card.Subtitle>
          <Card.Title >
              <a target="_blank" href={item.article_url} className="link">{item.article_title}</a>
          </Card.Title>
          
          <Card.Text className="text-muted date">
            {Moment(item.article_date).format('MMM DD YYYY')} 
          </Card.Text>
          </Card.Body>

          <Button variant="link" data-value={item.id} onClick={removeFav} className="delete-button">
              x
            </Button>
        
      </Col>

      </Row> 
    </Card>
   
    </Col>
    ))}
    </Row>
</div>
    )    
}
