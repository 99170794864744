import React from 'react'
import { Navbar } from 'react-bootstrap'
import { useState } from 'react'
import {Container, Nav, Form} from 'react-bootstrap'

export default function GlobalNavbar(props) {

  const searchField = props.searchField
  const setSearchField = props.setSearchField



    const handleButton = () => {
       // setEndDate(new Date());
        //setStartDate(new Date("2022-01-01T23:59:52Z"))
        //setFilter("")
        //setSource("")
    }
  
  return (
    <Navbar collapseOnSelect style={{backgroundColor: 'white'}} expand="lg"  variant="light" fixed="top">
    <Container fluid>
      <Navbar.Brand onClick={handleButton} href="#home">
              <img
            src= 'https://i.ibb.co/zr5qCzs/Capture.png'
            width="250"
            height="100"
           
            alt="Pulse"
          />
         </Navbar.Brand>

      
         
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="responsive-navbar-nav">    
      <Nav className="me-auto">
      
      </Nav>
      <Nav>
      <Form.Group controlId="formName" className="d-flex">
                <Form.Control type="search"  className="me-2"  aria-label="Search" onClick={handleButton} onChange={(e) => setSearchField(e.target.value)} placeholder="Search" />
            </Form.Group>
      </Nav>
      </Navbar.Collapse>
    </Container>
</Navbar>  
  )
}
