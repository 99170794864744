
import { Container, Row, Col, Form, Accordion } from 'react-bootstrap';
import './App.css';
import GlobalNavbar from './Components/Navbar';
import Sidefilters from './Components/Sidefilters';
import Feed from './Components/Feed';
import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';



//import logo from './images/logo.png'


function Highlights() {

  const [articles, setArticles] = useState([]);
  const [filter, setFilter] = useState("")
  const [source, setSource] = useState("")
  const [startDate, setStartDate] = useState(new Date("2022-01-01T23:59:52Z"));
  const [endDate, setEndDate] = useState(new Date()); 
  const [searchField, setSearchField] = useState("")
  const [currentItems, setCurrentItems] = useState("")
  const [deleted, setDeleted] = useState(true)
  const [isLoading, setLoading] = useState()
  const [firstArt, setFirstArt] = useState(null)
  const [secondRow, setSecondRow] = useState(null)
  const [thirdRow, setThirdRow] = useState(null)
  const [variable1, setVariable1] = useState(null)
  const [variable2, setVariable2] = useState(null)
  const [variable3, setVariable3] = useState(null)
 

  const loadData = () => {

    window.scrollTo(0, 0)
  setLoading(true)
  fetch('https://stark-atoll-13145.herokuapp.com/articles?' + new URLSearchParams({
    source: source,
    provider: filter,
    startDate: startDate,
    endDate: endDate,
    search: searchField
  }))
  .then(response => response.json())
  .then( setLoading(false))
  .then(data => {
    
    setArticles(data.data
      .sort((a,b) => new Date(b.article_date) - new Date(a.article_date))
      .filter(it => it.article_title.includes(filter))
      .filter(it => it.article_source.includes(source))
      .filter(it => new Date(it.article_date).getTime() >= startDate.getTime() && new Date(it.article_date).getTime() <= endDate.getTime() )
      .filter(it => it.article_title.toLowerCase().includes(searchField.toLowerCase()))
      .filter(it => it.favorite == true )
      //setPageCount(Math.ceil(articles.filter(it => it.article_title.includes(filter)).filter(it => it.article_source.includes(source)).filter(it => new Date(it.article_date).getTime() >= startDate.getTime() && new Date(it.article_date).getTime() <= endDate.getTime() ).filter(it => it.article_title.toLowerCase().includes(searchField.toLowerCase())).length / itemsPerPage));
      
      )
   
    
    }
  
    )
    .then( setVariable1(true))
   

   
     
}

 

 useEffect(() => {loadData()}
  , [ filter, source, startDate, endDate, searchField, deleted. variable1])

useEffect(()=>{
  if(articles) {
    console.log('use Effect 1')
    setVariable2(true)
  }
}, [articles])

useEffect(()=>{
  if(variable2) {
    console.log('use Effect 2')
    setFirstArt(articles.slice(0,2))
    setSecondRow(articles.slice(2,14))
    setThirdRow(articles.slice(14,52))
    
  }
}, [variable2, articles])



  return(
    <>
        <GlobalNavbar searchField={searchField} setSearchField={setSearchField}></GlobalNavbar>
        <Container>
          <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0"> 
          <Accordion.Header>  </Accordion.Header>
            <Accordion.Body>
            <Row>
          <Col lg={12}>
            <Sidefilters filter={filter} setFilter={setFilter} source={source} setSource = {setSource} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}></Sidefilters>
            </Col>
          </Row>

            </Accordion.Body>
       
          
          </Accordion.Item>
          </Accordion>
          
          <Row>
            
            <Col lg={12}>
            <div>
              {isLoading ? <p>Loading</p> : ""}
              {firstArt ?  <Feed  data={firstArt} secondRow={secondRow} thirdRow = {thirdRow} currentItems={currentItems} deleted={deleted} setDeleted = {setDeleted} ></Feed> : <div>Loading</div>
              }
                  
              
            </div>
            </Col>

            
          </Row>
        </Container>
        
    
    </>

  )
}


export default Highlights;




